import React from 'react';
import { Recipe } from "../ApiService";
import { Card, RichCell } from "@vkontakte/vkui";
import './RecipePreview.css';

interface RecipePreviewProps extends Recipe {
  onClick(): void;
}

export const RecipePreview: React.FC<RecipePreviewProps> = ({ onClick,  ...recipe}) => {
  return (
    <RichCell
      onClick={onClick}
      caption={`${recipe.category} · ${recipe.cuisine_category}`}
      before={<Card style={{overflow: "hidden", margin: '6px 12px 6px 0'}}>
        <div className="RecipePreviewImg" style={{backgroundImage: `url(${recipe.image_url})`, width: '72px', height: '72px', }} />
      </Card>}
      >
      {recipe.name}
    </RichCell>
  );
}