import * as React from "react";
import { Button, Div, Group, Placeholder } from "@vkontakte/vkui";
import { Icon56UserAddOutline } from "@vkontakte/icons";

interface WelcomeProps {
  onNext(): void;
}

export const Welcome: React.FC<WelcomeProps> = ({ onNext }) => {
  return (
    <Group>
      <Div>
        <Placeholder
          icon={<Icon56UserAddOutline />}
          header="Выберите ингридиенты"
          action={<Button size="m" onClick={onNext}>Попробовать</Button>}>
          На основе добавленных вами продуктов будут составлены рецепты, которые подойдут вам больше остальных
        </Placeholder>
      </Div>
    </Group>);
};