import React, { useEffect } from 'react';
import {
  Banner,
  Button,
  Caption, Cell, Counter,
  Div,
  FixedLayout,
  Group,
  Headline, MiniInfoCell,
  PanelHeader,
  PanelHeaderBack, Subhead, Text,
  Title
} from "@vkontakte/vkui";
import { useRecipesContext } from "../context/recipes";
import { useMyProductsContext } from "../context/myProducts";
import './RecipeCard.css'
import { getProductAmountLabel } from "../utils";

interface RecipeCardProps {
  navToRecipeList(): void;
  navToShoppingList(): void;
}

export const RecipeCard: React.FC<RecipeCardProps> = ({navToRecipeList, navToShoppingList}) => {
  const {selectedRecipe: recipe, setSelectedRecipe} = useRecipesContext();
  const myProducts = useMyProductsContext();

  useEffect(() => {
    if (!recipe) {
      navToRecipeList()
    }
  });
  if (!recipe) {
    return null;
  }

  const myProductsIds = myProducts.state.map(p => p.id.toString());

  const acc = {has: 0, missing: 0};

  recipe.ingredients.forEach((product) => {
    if (myProductsIds.includes(product.ingredient_id)) {
      acc.has += 1;
    } else {
      acc.missing += 1;
    }
  }, {has: 0, missing: 0});

  const onNavBack = () => {
    setSelectedRecipe();
    navToRecipeList();
  }

  return (
    <>
      <PanelHeader
        left={<PanelHeaderBack onClick={onNavBack} />}>{recipe.name}</PanelHeader>
      <Group style={{paddingBottom: 108}}>
        <Div style={{
          height: 300,
          backgroundImage: `url(${recipe.image_url})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }} />
        <Cell badge={<Caption
          level="1"
          style={{color: 'var(--text_secondary)'}}
          weight="regular">{acc.has} из {recipe.ingredients.length}
        </Caption>}>
          <Title weight="semibold" level="3">
            Требуемые продукты
          </Title>
        </Cell>
        {recipe.ingredients.map(product => (
          <Cell
            key={product.id}
            badge={<Subhead
              style={{color: 'var(--text_secondary)'}}
              weight="regular">{getProductAmountLabel(product)}
            </Subhead>}
            after={!myProductsIds.includes(product.ingredient_id) &&
            <Text weight="regular" style={{color: "var(--destructive)"}}>Не хватает</Text>}>
            <Headline weight="regular">{product.ingredient_name}</Headline>
          </Cell>
        ))}
        {!!acc.missing && <Banner
          header="Не хватает ингридиентов?"
          subheader="Составьте список покупок с помощью нашего приложения!" />}
        <Div>
          <Title weight="semibold" level="3">Рецепт</Title>
        </Div>
        {recipe.cooking_steps.map((step, index) => {
          return <MiniInfoCell
            before={<div className="RecipeCardStepCount">{index + 1}</div>}
            textWrap="full"
            textLevel="primary"
          >
            {step}
          </MiniInfoCell>
        })}
        <FixedLayout filled vertical={"bottom"} style={{paddingBottom: 48}}>
          <Div>
            <Button
              size={'l'}
              onClick={navToShoppingList}
              stretched
              after={<Counter>{acc.missing}</Counter>}
            >
              Заказать недостающее
            </Button>
          </Div>
        </FixedLayout>
      </Group>
    </>);
};