export interface Product {
  id: string;
  name: string;
}

export interface ExtendedProduct extends Product {
  amount: number;
  unit: string;
}

export interface RecipeProduct{
  id: string;
  ingredient_name: string;
  amount: number;
  unit: string;
  ingredient_id: string;
}

export interface Recipe {
  id: number;
  name: string;
  portions: number;
  energy_data: {
    calories: string;
    carbohydrateContent: string;
    fatContent: string;
    proteinContent: string;
  };
  description: string;
  cuisine_category: string;
  category: string;
  cooking_steps: string[];
  cooking_time: number;
  image_url: string;
  ingredients: RecipeProduct[];
}

interface GetProductsResponse {
  ingredients: Product[];
}

interface GetRecipesByProductIdResponse {
  available_recipes: Recipe[];
  plus_50_percent_recipes: Recipe[];
  plus_five_recipes: Recipe[];
  plus_one_recipes: Recipe[];
}

class ApiService {
  HOST = process.env.NODE_ENV === "development" ? 'https://api-vk-food.secretdar.com' : 'https://api-vk-food.secretdar.com'; // @TODO FIX !

  fetch<T = {}>(endpoint: string, request?: Request): Promise<T> {
    return fetch(this.HOST + endpoint, request)
      .then(res => res.json())
      .then((data) => data);
  }

  searchProducts = async (search: string) => {
    return this.fetch<GetProductsResponse>('/ingredients/get?query='+search).then((data) => {
      return data.ingredients;
    });
  }

  getPopularProducts = async () => {
    return this.fetch<GetProductsResponse>('/ingredients/popular').then((data) => {
      return data.ingredients;
    });
  }

  getRecipesByProductIds = async (products: Product[]) => {
    return this.fetch<GetRecipesByProductIdResponse>(`/recipes/find-by-ingredients?ingredient_ids=${products.map(p => p.id).join(',')}`).then(res => {
      return [
        { id: 'available_recipes', name: 'Только из ваших ингредиентов', recipes: res.available_recipes},
        { id: 'plus_one_recipes', name: '+1 ингредиент', recipes: res.plus_one_recipes},
        { id: 'plus_five_recipes', name: '+5 ингредиентов', recipes: res.plus_five_recipes},
        { id: 'plus_50_percent_recipes', name: 'Ну хоть что-то', recipes: res.plus_50_percent_recipes},
      ]
    });
  }
}


const api = new ApiService();
export { api };
