import * as React from "react";
import { MyProductsProvider } from './myProducts';
import { RecipesProvider } from './recipes';

export const AppStateProvider: React.FC = ({children}) => {
  return <MyProductsProvider>
    <RecipesProvider>
      {children}
    </RecipesProvider>
  </MyProductsProvider>;
}