import React, { useState } from "react";
import { api, Recipe } from "../ApiService";
import { useMyProductsContext } from "./myProducts";

export interface RecipesContext {
  lists: {
    id: string,
    name: string,
    recipes: Recipe[]
  }[];
  selectedRecipe?: Recipe;
  setSelectedRecipe(r?: Recipe): void;
  get(): Promise<any>;
}

function useRecipesState() {
  const [recipes, setRecipes] = useState<RecipesContext["lists"]>([]);
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | undefined>();
  const { state: myProducts } = useMyProductsContext();

  const get = () => {
    return api.getRecipesByProductIds(myProducts).then(setRecipes);
  }

  return {
    lists: recipes,
    get,
    selectedRecipe,
    setSelectedRecipe,
  }
}


const Context = React.createContext<RecipesContext | null>(null);

export const RecipesProvider: React.FC = ({children}) => {
  const myProducts = useRecipesState();
  return <Context.Provider value={myProducts}>
    {children}
  </Context.Provider>;
}


export const useRecipesContext = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error('no context');
  }

  return context;
}