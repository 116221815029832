import * as React from "react";
import { Separator, Tabbar, TabbarItem } from "@vkontakte/vkui";

interface BottomNavProps {
  navToRecipes(): void;
  navToMyProducts(): void;
  activePanel: string
}

export const BottomNav: React.FC<BottomNavProps> = ({navToRecipes, navToMyProducts, activePanel}) => {
  return (
    <>
      <Tabbar shadow={false}>
      <Separator wide />
        <TabbarItem selected={activePanel !== 'my_ingredients'} onClick={navToRecipes}>Рецепты</TabbarItem>
        <TabbarItem selected={activePanel === 'my_ingredients'} onClick={navToMyProducts}>Мои продукты</TabbarItem>
      </Tabbar>
    </>);
};
