import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Div,
  FixedLayout,
  Group, Headline, List,
  PanelHeader,
  PanelHeaderButton,
  Placeholder,
  Search,
} from "@vkontakte/vkui";
import { api, Product } from "../ApiService";
import { ProductItem } from "../components/Product";
import { useMyProductsContext } from "../context/myProducts";
import { useRecipesContext } from "../context/recipes";

interface MyProductsProps {
  navToRecipes(): void;
  navToRecipeCard(): void;
}

const SEARCH_TIMEOUT = 500;

export const MyProducts: React.FC<MyProductsProps> = ({navToRecipes, navToRecipeCard}) => {
  const myProducts = useMyProductsContext();
  const recipes = useRecipesContext();
  const [searchResult, setSearchResult] = useState<Product[]>([]);
  const [popular, setPopular] = useState<Product[]>([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchText, setSearchText] = useState('');
  const searchTimeoutId = useRef<number | null>(null);

  const clearSearchTimeout = () => {
    if (searchTimeoutId.current) {
      window.clearInterval(searchTimeoutId.current);
    }
  }

  const updateProducts = () => {
    api
      .searchProducts(searchText)
      .then((data) => {
        setSearchResult(data);
      });
  }

  const getPopular = () => {
    api.getPopularProducts().then(data => {
      setPopular(data);
    });
  }

  useEffect(() => {
    getPopular();
    return () => {
      if (searchTimeoutId.current) {}
    }
  }, []);

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setSearchText(text);
  }

  useEffect(() => {
    if (searchText.length >= 3) {
      clearSearchTimeout();
      searchTimeoutId.current = window.setTimeout(updateProducts, SEARCH_TIMEOUT);
    }
  }, [searchText]);

  const hasNoMyProducts = !myProducts.state.length;

  const onClickRecipeNav = () => {
    if (recipes.selectedRecipe) {
      navToRecipeCard();
    } else {
      navToRecipes();
    }
  }

  return  (<>
    <PanelHeader
      left={<PanelHeaderButton onClick={() => myProducts.clearAll()}>Очистить все</PanelHeaderButton>}>
      Ингредиенты
    </PanelHeader>
    <Group>
      <FixedLayout vertical={"top"}>
        <Search
          value={searchText}
          onChange={onSearchInputChange}
          after={null}
          onFocus={() => setIsSearchActive(true)} />
      </FixedLayout>

      <FixedLayout filled vertical={"bottom"}>
        <Div>
          <Button
            size={'l'}
            onClick={onClickRecipeNav}
            stretched
            disabled={hasNoMyProducts}>{hasNoMyProducts ? 'Из ничего и выйдет ничего' : 'Показать рецепты'}</Button>
        </Div>
      </FixedLayout>
    {isSearchActive && searchText.length >= 3 ?
      <>
        <Div style={{ paddingTop: 60 }}>
          <Headline weight={"semibold"} style={{color: 'var(--text_secondary)'}}>Результаты поиска</Headline>
        </Div>
        {searchResult.length ?
          <List style={{ paddingBottom: 60}}>
            {searchResult.map((product) => {
              const isChecked = myProducts.has(product);
              return <ProductItem
                key={`search_${product.id}`}
                {...product}
                selected={isChecked}
                onSelect={() => isChecked ? myProducts.remove(product) : myProducts.add(product)}/>
            })}
          </List> :
          <Placeholder>Ничего не найдено</Placeholder>}
      </>
      :
      <>
        <Div style={{ paddingTop: 60 }}>
          <Headline weight={"semibold"} style={{color: 'var(--text_secondary)'}}>Популярные</Headline>
        </Div>
        <List>
          {popular.map((product) => {
            const isChecked = myProducts.has(product);
            return <ProductItem
              key={`popular_${product.id}`}
              {...product}
              selected={isChecked}
              onSelect={() => isChecked ? myProducts.remove(product) : myProducts.add(product)}/>
          })}
        </List>
        <Placeholder style={{paddingBottom: 60}}>Ищите остальные продукты через поиск</Placeholder>
      </>
    }
    </Group>
  </>)
};
