import React, { useEffect, useState } from 'react';
import { useRecipesContext } from "../context/recipes";
import { useMyProductsContext } from "../context/myProducts";
import {
  Button,
  Div, FixedLayout,
  Group,
  Headline, List,
  PanelHeader,
  PanelHeaderBack,
} from "@vkontakte/vkui";
import { ExtendedProduct } from "../ApiService";
import { ProductItem } from "../components/Product";

interface RecipeCardProps {
  navToRecipeList(): void;
  navToRecipeCard(): void;
}

const navToSamokat = () => {
  let url = "https://vk.com/eda#store=Samokat_spb";
  window.navigator.geolocation.getCurrentPosition((pos) => {
    url += `&lat=${pos.coords.latitude}&long=${pos.coords.longitude}`;
    window.open(url, "_blank");
  }, () => {
    window.open(url, "_blank");
  }, {
    timeout: 5000,
  });
}

export const ShoppingList: React.FC<RecipeCardProps> = ({navToRecipeList, navToRecipeCard}) => {
  const {selectedRecipe: recipe} = useRecipesContext();
  const myProducts = useMyProductsContext();

  const myProductsIds = myProducts.state.map(item => item.id.toString());
  const acc: {has: ExtendedProduct[], missing: ExtendedProduct[]} = {
    has: [],
    missing: []
  };
  recipe?.ingredients.forEach((product) => {
    let simpleProduct: ExtendedProduct = {
      id: product.ingredient_id,
      name: product.ingredient_name,
      unit: product.unit,
      amount: product.amount
    }
    if (myProductsIds.includes(product.ingredient_id)) {
      acc.has.push(simpleProduct);
    } else {
      acc.missing.push(simpleProduct);
    }
  }, {has: 0, missing: 0});

  const [selectedProducts, setSelectedProducts] = useState(acc.missing.map(p => p.id));

  useEffect(() => {
    if (!recipe) {
      navToRecipeList()
    }
  });

  if (!recipe) {
    return null;
  }

  const toggleProduct = (productId: string) => {
    if (selectedProducts.includes(productId)) {
      setSelectedProducts(selectedProducts.filter(id => id !== productId))
    } else {
      setSelectedProducts([
        ...selectedProducts,
        productId
      ])
    }
  }

  return (
    <>
      <PanelHeader
        left={<PanelHeaderBack onClick={navToRecipeCard} />}>Список покупок</PanelHeader>
      <Group style={{paddingBottom: 60}}>
        <Div>
          <Headline weight="regular" style={{color: 'var(--text_secondary)'}}>Не хватает</Headline>
        </Div>
        <List>
          {acc.missing.map(product => (
            <ProductItem
              key={product.id}
              {...product}
              selected={selectedProducts.includes(product.id)}
              onSelect={() => toggleProduct(product.id)} />
          ))}
        </List>
        <Div>
          <Headline weight="regular" style={{color: 'var(--text_secondary)'}}>Докупить</Headline>
        </Div>
        <List>
          {acc.has.map(product => (
            <ProductItem
              key={product.id}
              {...product}
              selected={selectedProducts.includes(product.id)}
              onSelect={() => toggleProduct(product.id)} />
          ))}
        </List>
        <FixedLayout filled vertical={"bottom"}>

          <Div>
            <Button
              size={'l'}
              stretched
              onClick={navToSamokat}
            >
              Заказать!
            </Button>
          </Div>
        </FixedLayout>
      </Group>
    </>);
}