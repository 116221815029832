import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import View from '@vkontakte/vkui/dist/components/View/View';
import { FixedLayout, Panel, Root } from '@vkontakte/vkui';

import { Welcome } from "./panels/Welcome";
import { MyProducts } from "./panels/MyProducts";
import { AppStateProvider } from "./context";
import { RecipesList } from "./panels/RecipesList";
import { RecipeCard } from "./panels/RecipeCard";
import { BottomNav } from "./components/BottomNav";
import { ShoppingList } from "./panels/ShoppingList";

const App: React.FC = () => {
	const [activePanel, setActivePanel] = useState("welcome");

	useEffect(() => {
		document.body.classList.remove('loading');
		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppUpdateConfig') {
				const schemeAttribute = document.createAttribute('scheme');
				// @ts-ignore
				schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
				document.body.attributes.setNamedItem(schemeAttribute);
			}
		});
	}, []);

	const bottom = (
		<FixedLayout filled vertical="bottom">
			<BottomNav
				activePanel={activePanel}
				navToRecipes={() => setActivePanel('recipes_list')}
				navToMyProducts={() => setActivePanel('my_ingredients')}
			/>
		</FixedLayout>);


	return (
		<AppStateProvider>
			<Root activeView={"main"}>
				<View id="main" activePanel={activePanel}>
					<Panel id={"welcome"}>
						<Welcome onNext={() => {
							setActivePanel("my_ingredients");
						}} />
					</Panel>
					<Panel id="my_ingredients">
						<MyProducts
							navToRecipes={() => setActivePanel('recipes_list')}
							navToRecipeCard={() => setActivePanel('recipes_card')}
						/>
					</Panel>
					<Panel id="recipes_list">
						<RecipesList
							navToRecipeCard={() => setActivePanel('recipes_card')} />
						{bottom}
					</Panel>
					<Panel id="recipes_card">
						<RecipeCard
							navToRecipeList={() => setActivePanel('recipes_list')}
							navToShoppingList={() => setActivePanel('shopping_list')} />
						{bottom}
					</Panel>
					<Panel id="shopping_list">
						<ShoppingList
							navToRecipeList={() => setActivePanel('recipes_list')}
							navToRecipeCard={() => setActivePanel('recipes_card')}
						/>
					</Panel>
				</View>
			</Root>
		</AppStateProvider>
	);
}

export default App;

