import React, { useEffect, useState } from "react";
import { Product } from "../ApiService";

export interface MyProductsContext {
  state: Product[];
  add(p: Product): void;
  remove(p: Product | string): void;
  clearAll(): void;
  has(p: Product | string): boolean;
}

const LOCAL_STORAGE_KEY = 'vk-food-my-products';

const getProductsFromLocalStorage = () => {
  const raw = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!raw) {
    return
  }
  try {
    const parsed = JSON.parse(raw);
    if (!Array.isArray(parsed)) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      return
    }
    return parsed.filter((item) => item.id && item.name);
  } catch(e) {}
}

function useMyProductsState(): MyProductsContext {
  const [myProducts, setMyProducts] = useState<Product[]>(getProductsFromLocalStorage() || []);

  const add = (product: Product) => {
    setMyProducts([...myProducts, product]);
  }

  const remove = (product: Product | string) => {
    const id = typeof product === 'string' ? product : product.id;
    setMyProducts(myProducts.filter((p) => p.id !== id));
  }

  const clearAll = () => setMyProducts([]);

  const has = (product: Product | string) => {
    const id = typeof product === 'string' ? product : product.id;
    return !!myProducts.find((item) => item.id === id);
  }

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(myProducts));
  }, [myProducts]);

  return {
    state: myProducts,
    add,
    remove,
    clearAll,
    has
  }
}

const Context = React.createContext<MyProductsContext | null>(null);

export const MyProductsProvider: React.FC = ({children}) => {
  const myProducts = useMyProductsState();
  return <Context.Provider value={myProducts}>
    {children}
  </Context.Provider>;
}


export const useMyProductsContext = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error('no context');
  }

  return context;
}