import React from 'react';
import { Product } from "../ApiService";
import { Caption, Cell } from "@vkontakte/vkui";
import { getProductAmountLabel } from "../utils";

interface ProductItemProps extends Product {
  selected?: boolean;
  onSelect?: () => void;
  onRemove?: () => void;
  amount?: number;
  unit?: string;
}

export const ProductItem: React.FC<ProductItemProps> = ({ selected, onSelect, onRemove, ...product }) => {
  return (
    <Cell
      selectable={!!onSelect}
      checked={selected}
      onChange={onSelect}
      onRemove={onRemove}
      removable={!!onRemove}
      after={product.amount && product.unit && <Caption level="2" weight="regular">{getProductAmountLabel(product)}</Caption>}>
      {product.name}
    </Cell>
  );
};
