import React from "react";
import ReactDOM from "react-dom";
import bridge from "@vkontakte/vk-bridge";
import '@vkontakte/vkui/dist/vkui.css';
import App from "./App";
import './index.css'

// Init VK  Mini App
bridge.send("VKWebAppInit");

const SPLASH_SCREEN_TIME = 2000;
const now = +new Date();
// @ts-ignore
const timeout = Math.max(window.runTime + SPLASH_SCREEN_TIME - now, 0);
setTimeout(() => {
  ReactDOM.render(<App />, document.getElementById("root"));
}, timeout);
if (process.env.NODE_ENV === "development") {
  import("./eruda").then(({ default: eruda }) => {}); //runtime download
}
