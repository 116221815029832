import * as React from "react";
import { Div, Group, Headline, List, PanelHeader, Placeholder, Spinner } from "@vkontakte/vkui";
import { useRecipesContext } from "../context/recipes";
import { RecipePreview } from "../components/RecipePreview";
import { useEffect, useState } from "react";
import { Recipe } from "../ApiService";

interface RecipesListProps {
  navToRecipeCard(): void;
}

export const RecipesList: React.FC<RecipesListProps> = ({ navToRecipeCard }) => {
  const { lists, get, setSelectedRecipe } = useRecipesContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    get().then(() => setIsLoading(false));
  }, []);

  const onRecipeClick = (r: Recipe) => {
    setSelectedRecipe(r);
    navToRecipeCard();
  }

  const empty = !isLoading && !lists.find(list => !!list.recipes.length);

  return (
    <>
      <PanelHeader>Рецепты</PanelHeader>
      <Group style={{paddingBottom: 60}}>
        {isLoading && <Spinner style={{paddingBottom: 60}} />}
        {empty ?
          <Placeholder>Мы не смогли найти рецептов из ваших ингридиентов :(</Placeholder> :
          lists.map((list) => {
            if (!list.recipes.length) {
              return null
            }
            return <List key={list.id}>
              <Div>
                <Headline weight={"semibold"} style={{color: 'var(--text_secondary)'}}>{list.name}</Headline>
              </Div>
              {list.recipes.map(recipe => <RecipePreview {...recipe} key={recipe.id} onClick={() => onRecipeClick(recipe)}/>)}
            </List>
          })
        }
      </Group>
    </>);
};